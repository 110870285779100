import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import './styles/SessionHistory.css';  

function SessionHistory() {
  const [sessions, setSessions] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const response = await axios.get('/api/sessions/history', {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
        });
        setSessions(response.data.session_history);
      } catch (error) {
        setError('Error fetching session history');
        console.error("Error fetching history:", error);
      }
    };
    fetchSessions();
  }, []);

  return (
    <div className="session-history">
      <Header />
      
      <main className="session-history-main">
        <h1>Your EMDR Session History</h1>
        {error && <p className="error">{error}</p>}
        {!error && sessions.length === 0 && <p>No sessions found.</p>}
        <div className="session-cards">
          {sessions.map(session => (
            <div className="session-card" key={session.session_id}>
              <h2>{session.session_type}</h2>
              <p><strong>Start Time:</strong> {new Date(session.session_start).toLocaleString()}</p>
              <p><strong>End Time:</strong> {session.session_end ? new Date(session.session_end).toLocaleString() : 'In Progress'}</p>
              <p><strong>Mood Pre:</strong> {session.mood_pre}</p>
              <p><strong>Mood Post:</strong> {session.mood_post}</p>
              <p><strong>Comments Before:</strong> {session.comment_pre}</p>
              <p><strong>Comments After:</strong> {session.comment_post}</p>
            </div>
          ))}
        </div>
        <Link to="/subscribe" className="subscribe-button">
          Subscribe Now
        </Link>
      </main>
      
      <Footer />
    </div>
  );
}

export default SessionHistory;
