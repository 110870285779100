import React from 'react'; 
import './styles/Sidebar.css';

const Sidebar = ({ isVisible, closeSidebar, dotColor, setDotColor, bgColor, setBgColor, dotSize, setDotSize, dotSpeed, setDotSpeed, setExitSession }) => {
  const maxSpeedValue = 240;   
  const minSpeedValue = 60; 

  const handleSpeedChange = (e) => {
    const newSpeedValue = maxSpeedValue - parseFloat(e.target.value- minSpeedValue);
    console.log('New Speed Value:', newSpeedValue);
    setDotSpeed(newSpeedValue); 
  };

  const handleExitSession = () => {
    setExitSession(true);
    closeSidebar(); 
  };

  return (
    <div className={`sidebar ${isVisible ? 'visible' : ''}`}>
      <button onClick={closeSidebar} className="close-btn">&times;</button>
      <div className="color-options">
        <p>Dot Color:</p>
        {['red', '#FFF8F0', 'black', 'blue'].map((color) => (
          <button
            key={color}
            className="color-option"
            style={{ backgroundColor: color }}
            onClick={() => setDotColor(color)}
          />
        ))}
      </div>
      <div className="color-options">
        <p>Background Color:</p>
        {['red', '#FFF8F0', 'black', 'blue'].map((color) => (
          <button
            key={color}
            className="color-option"
            style={{ backgroundColor: color }}
            onClick={() => setBgColor(color)}
          />
        ))}
      </div>
      <div className="size-options">
        <p>Circle Size:</p>
        <input
          type="range"
          min="10"
          max="100"
          value={dotSize}
          onChange={(e) => setDotSize(e.target.value)}
        />
      </div>
      <div className="speed-option">
      <p>Ball Speed:</p>
      <input
        type="range"
        min={minSpeedValue}
        max={maxSpeedValue}
        step="1"
        value={maxSpeedValue - (dotSpeed-minSpeedValue)}
        onChange={handleSpeedChange}
      />
    </div>
      {/* Sound option placeholder */}
      <div className="sound-option">
        <p>Sound:</p>
        {/* Placeholder - Implement actual sound options as necessary */}
      </div>
      <div className="sidebar-footer">
        <button onClick={handleExitSession} className="exit-session-button">
          Exit Session
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
