import React, { useEffect, useRef } from 'react';
import './styles/DotMovement.css';

const DotMovement = ({ dotColor, dotSize, bgColor, dotSpeed, onCompletion, shouldRun, isPaused }) => {
  const dotRef = useRef(null);
  

  // This effect handles the animation end event
  useEffect(() => {
    const handleAnimationEnd = () => {
      if (shouldRun && onCompletion) {
        onCompletion();
        console.log("Dot done moving.")
      }
    };

    const currentDot = dotRef.current;
    if (currentDot) {
      currentDot.addEventListener('animationend', handleAnimationEnd);
    }

    return () => {
      if (currentDot) {
        currentDot.removeEventListener('animationend', handleAnimationEnd);
      }
    };
  }, [onCompletion, shouldRun]);

  // This effect sets the animation's inline styles
  useEffect(() => {
    const currentDot = dotRef.current;
    if (currentDot && shouldRun) {
      const singleBounceDuration = ((dotSpeed * 1000) / 42 / 2).toFixed(3);
      console.log('Starting animation with duration:', singleBounceDuration);
      currentDot.style.animation = `moveDot ${singleBounceDuration}ms linear 42`; // Running 42 times (21 full cycles)
      currentDot.style.animationPlayState = isPaused ? 'paused' : 'running';
    }
  }, [dotSpeed, shouldRun, isPaused]);
  
  // Render the dot with the styles derived from the props
  return (
    <div className="dot-movement-container" style={{ backgroundColor: bgColor }}>
      <div
        ref={dotRef}
        className="dot"
        style={{
          backgroundColor: dotColor,
          width: `${dotSize}px`,
          height: `${dotSize}px`,
        }}
      />
    </div>
  );
};

export default DotMovement;
