import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "./Modal";
import axios from "axios";
import DotMovement from "./DotMovement";
import DotContext from "../contexts/DotContext";
import MoodTracker from "./MoodTracker";
import "./styles/GuidedSession.css";
import { jwtDecode } from "jwt-decode";


function GuidedSession() {
  const navigate = useNavigate();
  const [script, setScript] = useState(null);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(true); // State to control modal visibility
  const [showDotMovement, setShowDotMovement] = useState(false);
 

  const {
    bgColor,
    dotColor,
    dotSize,
    dotSpeed,
    isPaused,
    setIsPaused,
    setSidebarVisible,
    sidebarVisible,
  } = useContext(DotContext);
  const [preSessionMood, setPreSessionMood] = useState(null); // Track pre-session mood
  const [moodModalVisible, setMoodModalVisible] = useState(true); // Control visibility of the mood modal
  // Near the top of your component, add a state hook for sessionId
  const [sessionId, setSessionId] = useState();

  const { exitSession, setExitSession } = useContext(DotContext);
  const [userId, setUserId] = useState(null); // State to store user ID

  useEffect(() => {
    // Decode the JWT token to get the user ID
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserId(decodedToken.user_id); // Set the user ID from the token
    }
  }, []);

  useEffect(() => {
    // When exitSession becomes true, trigger the modal
    if (exitSession) {
      setShowDotMovement(false);
      setMoodModalVisible(true);
      setIsModalVisible(false); // Ensure other modals are not visible
      setExitSession(false); // Reset for future use
    }
  }, [exitSession, setExitSession]);

  const handleOptionClick = (nextStepId) => {
    // If 'Finish' option was clicked, nextStepId will be null
    if (nextStepId === null) {
      endSession(); // Call the function that handles session ending
      setShowDotMovement(false); // Stop showing the dot movement
      setIsModalVisible(false); // Close the current step modal
      setMoodModalVisible(true); // Show the post-session mood tracker
    } else {
      // Find the next step in the script
      const nextStepIndex = script.steps.findIndex(step => step.id === nextStepId);
      setCurrentStepIndex(nextStepIndex);
    }
  };

  useEffect(() => {
    fetchScript(1); // Fetch initial script on component mount
    setMoodModalVisible(true);
  }, []);

  const handleMoodSubmit = async (mood, note, type) => {
    let payload;

    if (type === "pre") {
      payload = {
        user_id: userId, // Assuming user_id is known and hardcoded for now
        mood_pre: mood,
        comment_pre: note,
        session_type: "Guided", // Assuming 'Guided' is hardcoded for now
      };
    } else {
      // Building payload for a 'post' mood (at the end of a session)
      payload = {
        mood_post: mood,
        comment_post: note
      };
    }

    // Define the correct API endpoint
    const apiURL =
      type === "pre"
        ? "/api/sessions"
        : `/api/sessions/${sessionId}`;
    const method = type === "pre" ? "POST" : "PATCH";

    try {
      const response = await axios({
        method,
        url: apiURL,
        data: payload,
      });
      console.log(response.data);

      // Proceed to start the session after pre-mood has been submitted
      if (type === "pre") {
        setSessionId(response.data.session_id);
        setIsModalVisible(true); 
        setMoodModalVisible(false); 
        setPreSessionMood(mood);
      } else if (type === "post") {
        setSidebarVisible(false);
        navigate("/session-end");
      }
    } catch (error) {
      console.error("Error submitting mood:", error);
    }
  };

  const endSession = () => {
    setShowDotMovement(false); // End the dot movement
    setIsModalVisible(false); // Hide the script steps modal
    setMoodModalVisible(true); // Show the post-session mood tracker
  };

  const togglePause = () => {
    setIsPaused(!isPaused);
  };

  const toggleNav = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const handleNextClick = () => {
    if (currentStepIndex < script.steps.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
      setIsModalVisible(false);
      setShowDotMovement(true);
    } else {
      endSession();
    }
    setIsModalVisible(false);
  };

  // Log the updated state after it's been set
  useEffect(() => {
    console.log("showDotMovement state in GuidedSession:", showDotMovement);
  }, [showDotMovement]);

  const fetchScript = async (scriptId) => {
    try {
      const response = await axios.get(
        `/api/scripts/${scriptId}`
      );
      const fetchedScript = response.data;
      setScript(fetchedScript);
      console.log("Fetched Script");
    } catch (error) {
      console.error("Error fetching script:", error);
    }
  };

  const endDotMovement = () => {
    setShowDotMovement(false);
    setIsModalVisible(true);
  };

  const renderModalContent = () => {
    if (!script || script.steps.length === 0 || currentStepIndex >= script.steps.length) {
      return null;
    }
    const currentStep = script.steps[currentStepIndex];
    const isLastStep = currentStep.options.some(option => option.next_step_id === null);
  
    if (isLastStep) {
      // Add logic to handle the final step where 'Finish' option appears
      return (
        <div className="step-modal">
          <p>{currentStep.text}</p>
          {currentStep.options.map((option) => (
            <button 
              key={option.id}
              onClick={() => handleOptionClick(option.next_step_id)}
            >
              {option.text}
            </button>
          ))}
        </div>
      );
    }
    if (!script || script.steps.length === 0) {
      return null;
    }

    

    return (
      <div className="step-modal">
        <p>{currentStep.text}</p>
        {currentStep.options && currentStep.options.length > 0 ? (
          currentStep.options
            .sort((a, b) => a.id - b.id)
            .map((option) => (
              <button
                key={option.id}
                onClick={() => handleOptionClick(option.next_step_id)}
              >
                {option.text}
              </button>
            ))
        ) : (
          <button onClick={handleNextClick}>Next</button>
        )}
      </div>
    );
  };

  return (
    <div className="guided-session-page">
      {!sidebarVisible && (
        <button
          onClick={toggleNav}
          className="hamburger-menu"
          aria-label="toggle navigation"
        >
          <span className="hamburger">&#9776;</span>
        </button>
      )}

      {moodModalVisible && (
        <MoodTracker
          onSubmit={(mood, note) =>
            handleMoodSubmit(mood, note, preSessionMood ? "post" : "pre")
          }
        />
      )}

{!moodModalVisible && isModalVisible && (
      <Modal open={isModalVisible} onClose={() => setIsModalVisible(false)}>
        {renderModalContent()}
      </Modal>
    )}

      {showDotMovement && (
        <div className="dot-container">
          <button className="pause-button" onClick={togglePause}>
            {isPaused ? "Resume" : "Pause"}
          </button>
          <DotMovement
            shouldRun={showDotMovement}
            isPaused={isPaused}
            bgColor={bgColor}
            dotColor={dotColor}
            dotSize={dotSize}
            dotSpeed={dotSpeed}
            onCompletion={endDotMovement}
          />
        </div>
      )}
    </div>
  );
}

export default GuidedSession;
