import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot from react-dom/client
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Find the root container element
const container = document.getElementById('root');
// Create a root
const root = createRoot(container);
// Initial render: render the App component to the root
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// For performance measurements
reportWebVitals();
