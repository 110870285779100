import React, { useState } from 'react';
import './styles/MoodTracker.css';

function MoodTracker({ onSubmit }) {
  const [moodRating, setMoodRating] = useState(5); // Default mood rating
  const [note, setNote] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(moodRating, note); // Pass the mood rating and note to the parent handler
  };

  return (
    <form className="mood-tracker" onSubmit={handleSubmit}>
      <div className="mood-rating">
        <label>Mood Rating: </label>
        <input
          type="range"
          min="1"
          max="10"
          value={moodRating}
          onChange={(e) => setMoodRating(e.target.value)}
        />
        <div className="mood-faces">
          <span role="img" aria-label="frowning face">&#128577;</span> {/* Frowning Face */}
          <span role="img" aria-label="neutral face">&#128528;</span> {/* Neutral Face */}
          <span role="img" aria-label="grinning face">&#128578;</span> {/* Grinning Face */}
        </div>
      </div>
      <textarea
        placeholder="Additional notes..."
        value={note}
        onChange={(e) => setNote(e.target.value)}
      />
      <button type="submit">Submit</button>
    </form>
  );
}

export default MoodTracker;
