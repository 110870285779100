import React from 'react';
import './styles/Footer.css'


function Footer() {
  return (
    <footer className="footer">
      <p>&copy; {new Date().getFullYear()} Realign LLC</p>
    </footer>
  );
}

export default Footer;