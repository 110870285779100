import React from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
 

function About() {
  

  return (
    <div className="about">
      <Header />
      
      <main className="about-main">
        <h1>Welcome to EMDR Therapy</h1>
        <p>
          We love emdr because it kicks ass.
        </p>
        <Link to="/subscribe" className="subscribe-button">
          Subscribe Now
        </Link>
      </main>
      
      <Footer />
    </div>
    
  );
}

export default About;