import React, { useState, useContext, useEffect } from 'react';
import Modal from './Modal';
import DotMovement from './DotMovement';
import DotContext from '../contexts/DotContext';
import { useNavigate } from 'react-router-dom';
import MoodTracker from './MoodTracker';
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import './styles/UnguidedSession.css'

function UnguidedSession() {
  const [isModalVisible, setIsModalVisible] = useState(false); // Modal visibility state
  const [showDotMovement, setShowDotMovement] = useState(false);
  const [moodModalVisible, setMoodModalVisible] = useState(true); 
  const navigate = useNavigate();
  const [sessionId, setSessionId] = useState();
  const { bgColor, dotColor, dotSize, dotSpeed, isPaused, setIsPaused, setSidebarVisible, sidebarVisible} = useContext(DotContext);
  const [preSessionMood, setPreSessionMood] = useState(null);
  const [userId, setUserId] = useState(null); // State to store user ID

  useEffect(() => {
    // Decode the JWT token to get the user ID
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserId(decodedToken.user_id); // Set the user ID from the token
    }
  }, []);

  // Function to handle when the dot movement ends
  const endDotMovement = () => {
    setShowDotMovement(false); // Stop the dot movement
    setIsModalVisible(true); // Show the modal again for the next action
  };

  const { exitSession, setExitSession } = useContext(DotContext);

  useEffect(() => {
    // When exitSession becomes true, trigger the modal
    if (exitSession) {
      setIsModalVisible(false);
      setShowDotMovement(false);
      setMoodModalVisible(true);
      setExitSession(false);
    }
  }, [exitSession, setExitSession]);

  const handleMoodSubmit = async (mood, note, type) => {
    let payload;

    if (type === "pre") {
      payload = {
        user_id: userId, // Use the decoded user ID
        mood_pre: mood,
        comment_pre: note,
        session_type: "Unguided", // Assuming 'Unguided' is hardcoded for now
      };
    } else {
      // Building payload for a 'post' mood (at the end of a session)
      payload = {
        mood_post: mood,
        comment_post: note
      };
    }

    // Define the correct API endpoint
    const apiURL =
      type === "pre"
        ? "/api/sessions"
        : `/api/sessions/${sessionId}`;
    const method = type === "pre" ? "POST" : "PATCH";

    try {
      const response = await axios({
        method,
        url: apiURL,
        data: payload,
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } // Add the token to the headers
      });
      console.log(response.data);

      // Proceed to start the session after pre-mood has been submitted
      if (type === "pre") {
        setSessionId(response.data.session_id);
        setIsModalVisible(true); 
        setMoodModalVisible(false); 
        setPreSessionMood(mood);
      } else if (type === "post") {
        setSidebarVisible(false);
        navigate("/session-end");
      }
    } catch (error) {
      console.error("Error submitting mood:", error);
    }
  };

  const toggleNav = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const togglePause = () => {
    console.log("Clicked Pause")
    setIsPaused(!isPaused);
  };

  const handleUserChoice = (continueSession) => {
    if (continueSession) {
      setShowDotMovement(true);
      setIsModalVisible(false);
    } else {
      setIsModalVisible(false);
      setMoodModalVisible(true);
    }
  };

  // Function to render the modal content for the unguided session
  const renderModalContent = () => {
    return (
      <div className='step-modal'>
        <p>Do you want to continue?</p>
        <button onClick={() => handleUserChoice(true)}>Yes</button>
        <button onClick={() => handleUserChoice(false)}>No</button>
      </div>
    );
  };

  return (
    <div className="unguided-session-page">
     {!sidebarVisible && ( // This conditionally renders the button based on sidebarVisible
        <button onClick={toggleNav} className="hamburger-menu" aria-label="toggle navigation">&#9776;
          <span className="hamburger"></span>
        </button>
      )}

{moodModalVisible && (
        <MoodTracker
          onSubmit={(mood, note) => handleMoodSubmit(mood, note, preSessionMood ? 'post' : 'pre')}
        />
      )}
      
      {isModalVisible && (
        <Modal open={isModalVisible} onClose={() => setIsModalVisible(false)}>
          {renderModalContent()}
        </Modal>
      )}
      {showDotMovement && (
        <div className="dot-container">
        <button className="pause-button" onClick={togglePause}>
        {isPaused ? 'Resume' : 'Pause'}
      </button>
      <DotMovement
          shouldRun={showDotMovement}
          isPaused={isPaused}
          bgColor={bgColor}
          duration={60} 
          dotColor={dotColor} 
          dotSize={dotSize}
          dotSpeed={dotSpeed}
          onCompletion={endDotMovement}
        />
        </div>
      )}
      {/* Additional unguided session content goes here if required. */}
    </div>
  );
}

export default UnguidedSession;
