import React from 'react';



const DotContext = React.createContext({
  dotColor: 'black',
  setDotColor: () => {},
  bgColor: '#FFF8F0',
  setBgColor: () => {},
  dotSize: 20,
  setDotSize: () => {},
  dotSpeed: 90,
  setDotSpeed: () => {},
  sidebarVisible: false,
  setSidebarVisible: () => {},
  shouldRun: false,
  setShouldRun: () => {},
  isPaused: false,
  setIsPaused: () => {},
  exitSession: false, 
  setExitSession: () => {},

});

export default DotContext;