import React from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
 

function Subscribe() {
  

  return (
    <div className="subscribe">
      <Header />
      
      <main className="subscribe-main">
        <h1>Welcome to EMDR Therapy</h1>
        <p>
          Subscribe to our emdr app.
        </p>
        <Link to="/subscribe" className="subscribe-button">
          Subscribe Now
        </Link>
      </main>
      
      <Footer />
    </div>
    
  );
}

export default Subscribe;