import React from 'react';
import Dialog from '@mui/material/Dialog';

const Modal = ({ open, children, onClose }) => {

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      // Do not close the modal if clicked outside of it
      event.preventDefault();
    } else {
      // Allow close for other reasons (if any are defined)
      onClose();
    }
  };

    return (
      <Dialog 
        open={open} 
        onClose={handleClose} 
        aria-labelledby="responsive-dialog-title" 
        disablebackdropclick="true"
        >
        {children}
      </Dialog>
    );
  };
  
  export default Modal;