import React from 'react';
import { Link } from 'react-router-dom';
import './styles/Home.css'; 
import Footer from './Footer';
import Header from './Header';

function Home() {
  

  return (
    <div className="home">
      <Header />
      
      <main className="home-main">
        <h1>Welcome to Realign EMDR Therapy</h1>
        <p>
          Eye Movement Desensitization and Reprocessing (EMDR) is a psychotherapy treatment that was originally designed to alleviate the distress associated with traumatic memories. EMDR therapy facilitates the accessing and processing of traumatic memories to bring these to an adaptive resolution.
        </p>
        <p>
          EMDR therapy shows that the mind can in fact heal from psychological trauma much as the body recovers from physical trauma. When you cut your hand, your body works to close the wound. If a foreign object or repeated injury irritates the wound, it festers and causes pain. Once the block is removed, healing resumes. EMDR therapy demonstrates that a similar sequence of events occurs with mental processes. The brain's information processing system naturally moves toward mental health.
        </p>
        <p>
          If the system is blocked or imbalanced by the impact of a disturbing event, the emotional wound festers and can cause intense suffering. Once the block is removed, healing resumes. Using the detailed protocols and procedures learned in EMDR therapy training sessions, clinicians help clients activate their natural healing processes.
        </p>
        <p>
          We offer both guided and unguided EMDR sessions for our subscribers. We would love for you to join our community and begin your journey toward healing.
        </p>
        <Link to="/subscribe" className="subscribe-button">
          Subscribe Now
        </Link>
      </main>
      
      <Footer />
    </div>
    
  );
}

export default Home;
