import React, {useState} from 'react';
import {Link} from 'react-router-dom';

function Header() {
    const isLoggedIn = true;
  const [isNavVisible, setIsNavVisible] = useState(false);

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  return (
    <header className="app-header">
  <Link to="/" className="app-brand">Home</Link>
  <button onClick={toggleNav} className="nav-toggle" aria-label="toggle navigation">
    <span className="hamburger"></span>
  </button>
  <nav className={`app-nav ${isNavVisible ? 'show-nav' : ''}`}>
    <ul>
      <li><Link to="/about">About Us</Link></li>
      <li><Link to="/subscribe">Subscribe</Link></li>
      <li><Link to="/login">Login</Link></li>
      {isLoggedIn && (
        <>
          <li><Link to="/sessions">EMDR Session</Link></li>
        </>
      )}
    </ul>
  </nav>
</header>
  );
}

export default Header;

