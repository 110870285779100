import React from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import './styles/SessionEnd.css';  

function SessionEnd() {
  
  return (
    <div className="session-end">
      <Header />
      
      <main className="session-end-main">
        <h1>Thank you for using RealignMind!</h1>
        <div className="distress-copy">
          <p>Thank you for completing a session. If you are currently in distress, please reach out to your therapist. Alternatively, you can contact one of our partnered therapists or one of the crisis lines below for immediate support.</p>
          
          <h2>United States Suicide and Crisis Help</h2>
          <p><strong>Emergency:</strong> 911</p>
          <p><strong>Suicide & Crisis Lifeline:</strong> Call or text 988</p>
          <p><strong>National Domestic Violence Hotline:</strong> 1-800-799-7233</p>
          <p><strong>Crisis Text Line:</strong> Text "DESERVE" to 741-741</p>
          <p><strong>Lifeline Crisis Chat (Online live messaging):</strong> <a href="https://988lifeline.org/chat/" target="_blank" rel="noopener noreferrer">988lifeline.org/chat</a></p>
          <p><strong>Self-Harm Hotline:</strong> 1-800-DONT CUT (1-800-366-8288)</p>
          <p><strong>Essential local and community services:</strong> 211, <a href="https://www.211.org/" target="_blank" rel="noopener noreferrer">www.211.org</a></p>
          <p><strong>Planned Parenthood Hotline:</strong> 1-800-230-PLAN (7526)</p>
          <p><strong>American Association of Poison Control Centers:</strong> 1-800-222-1222</p>
          <p><strong>National Council on Alcoholism & Drug Dependency Hope Line:</strong> 1-800-622-2255</p>
          <p><strong>National Crisis Line - Anorexia and Bulimia:</strong> 1-800-233-4357</p>
          <p><strong>LGBT Hotline:</strong> 1-888-843-4564</p>
          <p><strong>TREVOR Crisis Hotline:</strong> 1-866-488-7386</p>
          <p><strong>AIDS Crisis Line:</strong> 1-800-221-7044</p>
          <p><strong>Veterans Crisis Line:</strong> <a href="https://www.veteranscrisisline.net" target="_blank" rel="noopener noreferrer">www.veteranscrisisline.net</a></p>
          <p><strong>TransLifeline:</strong> <a href="https://www.translifeline.org" target="_blank" rel="noopener noreferrer">www.translifeline.org</a> - 877-565-8860</p>

          <h2>International Support</h2>
          <p>If you're not in the United States, try one of the numbers listed here: <a href="https://en.wikipedia.org/wiki/List_of_suicide_crisis_lines" target="_blank" rel="noopener noreferrer">Wikipedia</a></p>
        </div>
        <h2>Or to see your session history:</h2>
        <Link to="/session-history" className="button">
          Session history
        </Link>
      </main>
      
      <Footer />
    </div>
  );
}

export default SessionEnd;
