import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import GuidedSession from './GuidedSession';
import UnguidedSession from './UnguidedSession';
import Footer from './Footer';
import Header from './Header';
import './styles/SessionSelection.css'

function SessionSelection() {
  

  return (
    <div className="session-selection-page">
      <Header />
      
      
      <main className="session-selection-main">
        <Link to="guided">
          <button>Start Guided Session</button>
        </Link>
        <Link to="unguided">
          <button>Start Unguided Session</button>
        </Link>
        {/* Add more buttons for additional session types as links */}
      </main>
      
      {/* Define routes for guided and unguided sessions here */}
      <Routes>
        <Route path="guided" element={
          <GuidedSession />
        } />
        <Route path="unguided" element={
          <UnguidedSession />
        } />
      </Routes>
        <Footer />
</div>

  );
}
export default SessionSelection;
