import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

function SignInSignUp() {
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const toggleForm = () => {
    setIsSignUp(!isSignUp);
  };

  const validateEmail = (email) => {
    // Simple email validation regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validatePassword = (password) => {
    // Password complexity regex pattern
    const passwordPattern = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    return passwordPattern.test(password);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const apiUrl = isSignUp ? '/api/register' : '/api/login';
  console.log(`Sending request to: ${apiUrl}`);

    // Validate email
    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address.');
      return;
    } else {
      setEmailError('');
    }

    // Validate password
    if (!validatePassword(password)) {
      setPasswordError(
        'Password must be at least 8 characters long and include a number and a symbol.'
      );
      return;
    } else {
      setPasswordError('');
    }

    try {
      const response = await axios({
        method: isSignUp ? 'post' : 'post',
        url: isSignUp ? '/api/register' : '/api/login',
        data: {
          email,
          password,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });
      
      console.log(response.data);
 
      if (isSignUp) {
        setSuccessMessage('User created successfully. Redirecting to login....');
        setTimeout(() => {
          setIsSignUp(false);
          setSuccessMessage('');
        }, 2000);
      } else {
        const { token } = response.data; // Destructure token from response.data
        console.log(token); // Log the token separately
        if (token) {
          localStorage.setItem('token', token); // Store the token in localStorage
          console.log("Token stored in localStorage"); // Confirm storing
          navigate('/sessions'); // Redirect to the sessions page
        } else {
          console.error("Token not found in the response");
        }
      }
    } catch (error) {
      console.error(isSignUp ? 'Registration failed:' : 'Login failed:', error);
      // Handle errors (e.g., display an error message to the user)
    }
 
  };

  return (
    <div className="sign-in-sign-up">
      <Header />
      <main className="sign-in-sign-up-main">
        <h1>{isSignUp ? 'Create an Account' : 'Sign In'}</h1>
        {successMessage && <p className="success-message">{successMessage}</p>}
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {emailError && <span className="error">{emailError}</span>}
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {passwordError && <span className="error">{passwordError}</span>}
          {isSignUp && (
            <>
              {/* Additional registration fields */}
            </>
          )}
          <button type="submit">{isSignUp ? 'Sign Up' : 'Sign In'}</button>
        </form>
        <p>
          {isSignUp ? (
            <>
              Already have an account? <button onClick={toggleForm}>Sign In</button>
            </>
          ) : (
            <>
              Don't have an account? <button onClick={toggleForm}>Create an account</button>
            </>
          )}
        </p>
      </main>
      <Footer />
    </div>
  );
}

export default SignInSignUp;
